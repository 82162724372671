.apexcharts-legend-series {
  display: flex;
  padding-bottom: 8px;
}

.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  right: -4px !important;
  top: 3px !important;
  height: 8px !important;
  width: 8px !important;
}

.apexcharts-legend.apx-legend-position-right {
  display: flex;
  top: 0px !important;
  left: unset !important;
  right: 50% !important;
  transform: translateX(220px) !important;
}

.apexcharts-legend.apx-legend-position-right .apexcharts-legend-series:first-child {
  margin-top: auto !important;
}

.apexcharts-legend.apx-legend-position-right .apexcharts-legend-series:last-child {
  margin-bottom: auto !important;
}

.apexcharts-legend.apx-legend-position-right .apexcharts-legend-series:only-child {
  margin: auto !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series:first-child {
  margin-left: auto !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series:last-child {
  margin-right: auto !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series:only-child {
  margin: auto !important;
}

.apexcharts-legend.apx-legend-position-bottom {
  max-height: 26px !important;
  flex-wrap: nowrap !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
  justify-content: flex-start !important;
  gap: 10px !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series {
  align-items: flex-start !important;
}

.apexcharts-legend-text {
  font-family: "Public Sans" !important;
  color: #212121 !important;
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
  background-color: #d4d4d4 !important;
  box-shadow: 0;
  -webkit-box-shadow: 0;
}

.apx-legend-position-right::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px !important;
}

.apx-legend-position-bottom::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 3px !important;
}

.apexcharts-pie-label {
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 500;
  filter: none;
}

.pie-tooltip {
  font-size: 12px !important;
  margin-bottom: 0 !important;
  border: none !important;
}

/* 
.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  gap: 8px;
}

.apexcharts-legend-series {
  display: flex;
  gap: 8px;
}

html[dir='rtl'] .apexcharts-tooltip .apexcharts-tooltip-marker {
  margin-left: 10px;
  margin-right: 0px;
} */
